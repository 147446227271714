import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="March 2018" subnav="release-notes">
      <div id="March2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          We did a few (read: so many) things to prepare for launch. Components
          3.0 and CSS 1.0 are live. This means a lot of deprecations—be sure to
          check our notes for necessary updates. Major components got some
          international love. Icon and color names are normalized, and we killed
          outline icons (so stick with the solids). Sketch has also been updated
          with new angle icons, normal play and auto skip. Seems lengthy, but we
          promise it’s worth reading.
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              upgradeUrl="https://github.com/hudl/uniform-ui-components/wiki/to-v3.0.0"
              version="3.0.0"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.0.0"
            />

            <div className={styles.changesList}>
              <Added>
                New{' '}
                <Link href="/components/icon" type="article" isDesignCodeLink>
                  icons
                </Link>{' '}
                for angles, normal play and auto skip.
              </Added>
              <Improved>
                Included <code>refs</code> for{' '}
                <Link
                  href="/components/buttons/button"
                  type="article"
                  isDesignCodeLink>
                  buttons
                </Link>
                ,{' '}
                <Link
                  href="/components/forms/input"
                  type="article"
                  isDesignCodeLink>
                  inputs
                </Link>{' '}
                and{' '}
                <Link
                  href="/components/forms/text-area"
                  type="article"
                  isDesignCodeLink>
                  text area
                </Link>
                .
              </Improved>
              <Improved>
                Included <code>defaultValue</code> prop to{' '}
                <Link
                  href="/components/selects/single-select"
                  type="article"
                  isDesignCodeLink>
                  single select
                </Link>{' '}
                and lookup select.
              </Improved>
              <Removed>
                Replaced old input components with a new{' '}
                <Link
                  href="/components/forms/input"
                  type="article"
                  isDesignCodeLink>
                  input
                </Link>{' '}
                component––just pass in a <code>type</code> prop and you’re set.
                <List>
                  <li>
                    <code>InputDate</code>
                  </li>
                  <li>
                    <code>InputEmail</code>
                  </li>
                  <li>
                    <code>InputFile</code>
                  </li>
                  <li>
                    <code>InputNumber</code>
                  </li>
                  <li>
                    <code>InputPassword</code>
                  </li>
                  <li>
                    <code>InputTel</code>
                  </li>
                  <li>
                    <code>InputText</code>
                  </li>
                  <li>
                    <code>InputTime</code>
                  </li>
                  <li>
                    <code>InputURL</code>
                  </li>
                </List>
              </Removed>
              <Removed>
                The old <code>select</code> has been replaced with the single
                select.
              </Removed>
              <Removed>
                <code>defaultValuesChecked</code> prop from{' '}
                <Link
                  href="/components/forms/checkbox"
                  type="article"
                  isDesignCodeLink>
                  checkbox group
                </Link>{' '}
                and checkbox group parent, and replaced it with the{' '}
                <code>valuesChecked</code> prop.
              </Removed>
              <Removed>
                The <code>theme</code> prop from the{' '}
                <Link
                  href="/components/forms/form-modifier"
                  type="article"
                  isDesignCodeLink>
                  form modifier
                </Link>{' '}
                component. Use{' '}
                <Link href="/components/themes" type="article" isDesignCodeLink>
                  environment and levels
                </Link>{' '}
                in its place.
              </Removed>
              <Removed>
                Props from the{' '}
                <Link href="/components/toast" type="article" isDesignCodeLink>
                  toast
                </Link>{' '}
                component:
                <List>
                  <li>
                    <code>autoHideDuration</code>
                  </li>
                  <li>
                    <code>default</code>
                  </li>
                  <li>
                    <code>error</code>
                  </li>
                  <li>
                    <code>identifier</code>
                  </li>
                  <li>
                    <code>loading</code>
                  </li>
                  <li>
                    <code>title</code>
                  </li>
                </List>
              </Removed>
              <Removed>
                The <code>isBlock</code> prop for buttons.
              </Removed>
              <Removed>
                All outline icons. Use the filled components in their place.
              </Removed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="CSS"
              upgradeUrl="https://github.com/hudl/uniform-ui-css/wiki/Upgrading-to-v1.0.0"
              version="1.0.0"
              versionUrl="https://github.com/hudl/uniform-ui-css/releases/tag/v1.0.0"
            />

            <div className={styles.changesList}>
              <Fixed>The focus states on various components.</Fixed>
              <Removed>
                Quite a few variables were{' '}
                <Link
                  href="/resources/code-variables/removed-variables"
                  type="article">
                  removed
                </Link>{' '}
                and mapped to replacement values. Most notably:
                <List>
                  <li>
                    the <code>uni-button--disabled</code> and{' '}
                    <code>uni-button--block</code> classes
                  </li>
                  <li>any unofficial button variants</li>
                  <li>legacy minimal buttons and legacy destroy button</li>
                  <li>legacy text, headlines, spacers, padding and theming</li>
                </List>
              </Removed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Android"
              upgradeUrl="https://github.com/hudl/android-framework/wiki/to-v1.0.0"
              version="1.0.0"
              versionUrl="https://github.com/hudl/android-framework/releases/tag/v1.0.0"
            />

            <div className={styles.changesList}>
              <Improved>
                <Link
                  href="https://github.com/hudl/android-framework/pull/154"
                  type="article">
                  Updated token assets
                </Link>{' '}
                and icons. Icon names were also updated to a new naming scheme
                with logical separators.
              </Improved>
              <Removed>
                All outline icon variants have been removed. Use the filled
                versions in their place.
              </Removed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="iOS"
              upgradeUrl="https://github.com/hudl/ios-uniform-ui/wiki/to-v4.0.0"
              version="4.0.0"
              versionUrl="https://github.com/hudl/ios-uniform-ui/tree/master/UniformUI"
            />

            <div className={styles.changesList}>
              <Improved>
                <Link
                  href="https://github.com/hudl/ios-uniform-ui/pull/28"
                  type="article">
                  Updated token assets
                </Link>{' '}
                with naming schemes to make icons and colors easier to read and
                match.
              </Improved>
              <Removed>
                All outline icon variants have been removed. Use the filled
                versions in their place.
              </Removed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              upgradeUrl="https://github.com/hudl/rn-uniform-ui/wiki/to-v4.0.0"
              version="4.0.0"
              versionUrl="https://github.com/hudl/rn-uniform-ui/blob/master/CHANGELOG.md"
            />

            <div className={styles.changesList}>
              <Added>
                <Link href="/components/icon/code?reactnative" type="article">
                  Icon
                </Link>{' '}
                components are now on React Native.
              </Added>
              <Improved>
                <Link
                  href="https://github.com/hudl/rn-uniform-ui/pull/21"
                  type="article">
                  Updated token assets
                </Link>{' '}
                to normalize how we handle colors across platforms.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>
                Eight new icons. Just open Sketch and sync the updates.
                <List>
                  <li>Angle 1</li>
                  <li>Angle 2</li>
                  <li>Angle 3</li>
                  <li>Angle 4</li>
                  <li>Angle 5</li>
                  <li>Angle 6</li>
                  <li>Auto skip</li>
                  <li>Normal play</li>
                </List>
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
